export const hostLocale = computed(() => {
  if (location.hostname.endsWith('.ru')) {
    return 'ru'
  }

  return 'en'
})

export const contactUsEmail = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'nihao@laoshi.ru'
    default:
      return 'app@laoshi.io'
  }
}

export const termsOfUseUrl = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://drive.google.com/file/d/1nKSkC5RiJ-mufCbG3wj3EmvAYktUrVWX/view?usp=sharing'
    default:
      return 'https://laoshi.io/terms'
  }
}

export const privacyPolicyUrl = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://drive.google.com/file/d/1Xf6wegku3g4DLgGvl5ZsnEXVdtqKaNxU/view?usp=sharing'
    default:
      return 'https://laoshi.io/privacy-policy-en'
  }
}

export const companyAddress = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'Москва, ул. Новодмитровская, 1, стр. 6'
    default:
      return '2810 N Church St, Ste 87474, Wilmington, Delaware 19802-4447, United States'
  }
}

export const companyName = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'ООО «ЛАОШИ РУ»'
    default:
      return 'Laoshi Inc.'
  }
}
